<script lang="ts" setup>
  import type { IGenreCategory } from '~/types/movies';

  interface IProps {
    data: IGenreCategory;
  }
  const props = defineProps<IProps>();
</script>

<template>
  <div class="genre-card">
    <v-lazy class="genre-card__image-container">
      <v-image class="movie-card__image" :src="props.data.icon" :alt="props.data.name" transition="fade" transition-all>
        <template #loading><v-loader-card type="continue" radius="16px" /></template>
        <template #error><v-placeholder type="continue" background-placeholder radius="16px" /></template>
      </v-image>
    </v-lazy>
    <nuxt-link :to="{ name: 'browse-genre-slug', params: { slug: props.data.slug } }" class="genre-card__overlay">
      <p class="genre-card__title">{{ props.data.name }}</p>
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
  .genre-card {
    position: relative;
    display: grid;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%), #0b0b0b;

    &__image-container {
      //display: flex;
      width: 248px;
      height: 180px;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
    }

    &__overlay {
      position: absolute;
      inset: 0;
      padding: 16px;
    }
    &__title {
      color: $main_yellow_light;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
    @media (max-width: $retina) {
      &__image-container {
        width: 180px;
        height: 130px;
      }
      &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
</style>
